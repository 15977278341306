export class Tab {
  constructor(key) {
    this._key = key;
    this._element = document.querySelector(this._key);
    this._actionBar = document.querySelector(
      `[data-ouzo-card-tab-actionbar="${this._key}"]`
    );
  }

  get key() {
    return this._key;
  }

  isAttached() {
    return this._element.isConnected &&
      this._actionBar?.isConnected
  }

  setActive(active) {
    if(active) {
      this._element.classList.add("active");
      this._actionBar?.classList?.remove("d-none");
    }
    else {
      this._element.classList.remove("active");
      this._actionBar?.classList?.add("d-none");
    }
  }

  destroy() {
    this._element = null;
    this._actionBar = null;
  }
}
