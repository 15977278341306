import { TabManager } from "./tab-manager"

const tabManager = new TabManager();

export default {
  TabManager,
  tabManager,
  start: function() {
    tabManager.start();
    window.tabManager = tabManager;
  }
};
