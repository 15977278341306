import { Tab } from "./tab";

export class TabManager {
  constructor() {
    this._tabs = new Map();
    this._linksSelector = '.ouzo-card-tab-link';
  }

  start() {
    this.init();
    this.attachEventListeners();
  }

  init() {
    this.cleanup();
    this.update();
  }

  attachEventListeners() {
    document.addEventListener("turbo:load", () => { this.init(); })
    $(document).on("shown.bs.tab", this._linksSelector, (e) =>{
      this.update();
    })
  }

  cleanup() {
    this._tabs.forEach((tab, key) => {
      if (!tab.isAttached()) {
        this._tabs.delete(key);
        tab.destroy();
      }
    }, this)
  }

  update() {
    const links = document.querySelectorAll(this._linksSelector);

    links.forEach((link) => {
      const key = link.attributes['href'].value;
      const active = link.classList.contains('active');
      if(!this._tabs.has(key)){
        const tab =  new Tab(key)
        this._tabs.set(key, tab);
        tab.setActive(active);
      } else {
        const tab = this._tabs.get(key);
        tab.setActive(active);
      }
    }, this );
  }
}
