var morphdom = require('morphdom');

class DOMUtils {
  static appendOrReplace(containerSelector, targetSelector, html, childrenOnly=null) {
    const target = (targetSelector instanceof HTMLElement) ? targetSelector : document.getElementById(targetSelector)

    if(target) {
      this.replace(target, html, childrenOnly)
    }
    else {
      const container = $(containerSelector)
      container.append(html)
    }
  }

  static replace(target, html, childrenOnly=null) {
    if(!target)
      return

    if(!!!html) {
      target.replaceWith(html)
    } else {
      let morphdomOptions = {
        childrenOnly: childrenOnly,
        onNodeAdded: function (node) {
          if (node.nodeName === 'SCRIPT') {
            var script = document.createElement('script');
            //copy over the attributes
            [...node.attributes].forEach( attr => {
              script.setAttribute(attr.nodeName ,attr.nodeValue)
            })

            script.innerHTML = node.innerHTML;
            node.replaceWith(script)
          }
        },
        onBeforeNodeDiscarded: function(node) {
          // Exclude div for cursor extension
          if (node.id && node.id == "cursor-guide") {
            return false
          }

          return true
        },
        onBeforeElUpdated: function (fromEl, toEl) {
          // Handle UI Permanent
          if (fromEl.hasAttribute("data-rx-permanent") &&
              toEl.hasAttribute("data-rx-permanent") &&
              toEl.hasAttribute("data-rx-no-render")) {
            return false
          }

          // Handle elements that we dont want to morph
          if(toEl.hasAttribute("data-rx-render") &&
             toEl.getAttribute("data-rx-render") == "replace") {
            fromEl.replaceWith(toEl)
            return false
          }

          // Handle scripts
          if (fromEl.nodeName === "SCRIPT" && toEl.nodeName === "SCRIPT") {
            var script = document.createElement('script');
            //copy over the attributes
            [...toEl.attributes].forEach( attr => {
              script.setAttribute(attr.nodeName ,attr.nodeValue)
            })

            script.innerHTML = toEl.innerHTML;
            fromEl.replaceWith(script)
            return false;
          }
          return true;
        }
      };
      morphdom.default(target, html, morphdomOptions);
    }
  }

  static trigger(name) {
    const event = document.createEvent('Event');
    event.initEvent(name, true, true);
    document.dispatchEvent(event);
  }
}

export { DOMUtils }
